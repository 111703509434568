// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Your app code
// console.log(`Hello ${process.env.HELLO}`);



$(window).bind('mousewheel DOMMouseScroll', function(event){
    if ($(window).scrollTop() > 0) {
        $('header').addClass('shrink');
    }
    else {
      $('header').removeClass('shrink');
    }
});




$(document).ready(function(){
  if ($(window).scrollTop() > 0) {
    $('header').addClass('shrink');
}
else {
  $('header').removeClass('shrink');
}
// $('#videoModal').modal('toggle');



setTimeout(function() { 

  

  $('html, body').animate({
    scrollTop: $(".video-section").offset().top - 100
  }, 300);
  $('#icetea-video').get(0).load();
  
  $('#icetea-video').get(0).play();
}, 1500);

setTimeout(function() { 

  $('header').addClass('shrink');
}, 1900);


$('.watch-video-button').click(function(){

  $('html, body').animate({
    scrollTop: $(".video-section").offset().top - 100
  }, 300);
  $('#icetea-video').get(0).load();
  
  $('#icetea-video').get(0).play();

});



// $('#videoModal').on('shown.bs.modal', function (e) {
//   $('#icetea-video').get(0).load();
  
//   $('#icetea-video').get(0).play();

 
// });

// $('#videoModal').on('hide.bs.modal', function (e) {
//   $('#icetea-video').get(0).pause();
  
// });

// $('.watch-video-button').click(function(){
  
//   $('#videoModal2').modal('toggle');
//   $('#icetea-video2').get(0).load();  
//   $('#icetea-video2').get(0).play();
// });

// $('#videoModal2').on('hide.bs.modal', function (e) {
//   $('#icetea-video2').get(0).pause();
  
// });


});



$(window).bind('mousewheel DOMMouseScroll', function(event){
  if ($(window).scrollTop() > $('.second-section').offset().top - $(window).height() && $(window).scrollTop() < $('.second-section').offset().top + $('.second-section').height() ) {
      
    $('.s1-lemon').css('transform','translateY( '+ $(window).scrollTop()/5 +'px )');

    

   

  }
  
});

$(window).bind('mousewheel DOMMouseScroll', function(event){
  if ($(window).scrollTop() > $('.third-section').offset().top - $(window).height() - 300 && $(window).scrollTop() < $('.third-section').offset().top + $('.third-section').height() ) {
      
    

    $('.s1-ice').css('transform','translateY( '+ $(window).scrollTop()/10 +'px )');
    $('.s1-mint').css('transform','translateY( '+ -1 * $(window).scrollTop()/30 +'px )');

   

  }
  
});

$(window).bind('mousewheel DOMMouseScroll', function(event){
  if ($(window).scrollTop() > $('.third-section').offset().top - $(window).height() - 300 && $(window).scrollTop() < $('.third-section').offset().top + $('.third-section').height() ) {
      
    

    $('.s2-ice').css('transform','translateY( '+ -1 * $(window).scrollTop()/40 +'px )');
   
   

  }
  
});

$(window).bind('mousewheel DOMMouseScroll', function(event){
  if ($(window).scrollTop() > $('.fourth-section').offset().top - $(window).height() - 300 && $(window).scrollTop() < $('.fourth-section').offset().top + $('.fourth-section').height() ) {
      
    

    $('.s3-pineapple').css('transform','translateY( '+ -1 * $(window).scrollTop()/20 +'px )');
   
   

  }
  
});


$(window).bind('mousewheel DOMMouseScroll', function(event){
  if ($(window).scrollTop() > $('.fifth-section').offset().top - $(window).height() - 500 && $(window).scrollTop() < $('.fifth-section').offset().top + $('.fifth-section').height() ) {
      
    

    $('.s4-strawberry').css('transform','translateY( '+ -1 * $(window).scrollTop()/40 +'px )');

    $('.s4-ice').css('transform','translateY( '+ $(window).scrollTop()/50 +'px )');
   
    $('.s4-mint').css('transform','translateY( '+ -1 * $(window).scrollTop()/40 +'px )');
   

  }
  
});

